import React from "react";
import Badge from "../Components/Common/badge";
import InformationItem from "../Components/Common/informationItem";
import { getRandomBackgroundColor } from "../Utils/backgroundColor";

import "./about.css";

class About extends React.Component {
  strongKnowledgeLanguages = ["Romanian", "English"];
  mediumKnowledgeLanguages = ["French", "German"];
  wannabeKnowledgeLanguages = ["Russian"];

  getLanguages = (knowlegeType) => {
    let chosenLanguages = undefined;
    switch (knowlegeType) {
      case "strong":
        chosenLanguages = this.strongKnowledgeLanguages;
        break;
      case "medium":
        chosenLanguages = this.mediumKnowledgeLanguages;
        break;
      case "wannabe":
        chosenLanguages = this.wannabeKnowledgeLanguages;
        break;
      default:
        throw Error("Languages error");
    }

    return (
      <React.Fragment>
        {chosenLanguages.map((language) => {
          return (
            <Badge
              key={language}
              text={language}
              color={getRandomBackgroundColor()}
              textDark={Math.random() >= 0.5}
            />
          );
        })}
      </React.Fragment>
    );
  };

  getSufficientKnowledgeContent = () => {
    const suffiecientKnowledge = [
      "Terraform",
      "JUnit",
      "Angular",
      "Oracle DB",
      "SQLite",
      "Haskell",
      "Prolog",
      "Racket",
      "Verilog",
      "MATLAB",
      "HTML",
      "CSS",
      "Kubernetes",
    ];

    return (
      <React.Fragment>
        {suffiecientKnowledge.map((suffiecientKnowledge) => {
          return (
            <Badge
              key={suffiecientKnowledge}
              text={suffiecientKnowledge}
              color={getRandomBackgroundColor()}
              textDark={Math.random() >= 0.5}
            />
          );
        })}
      </React.Fragment>
    );
  };

  getStrongKnowledgeContent = () => {
    const strongKnowledge = [
      "Python",
      "FastAPI",
      "Java",
      "Spring Framework",
      "PostgreSQL",
      "Artificial Intelligence",
      "Docker",
      "C/C++",
      "Data structures & Algorithms",
      "Parallel and Distributed programming",
      "Git",
      "Functional Programming",
      "Linux",
    ];

    return (
      <React.Fragment>
        {strongKnowledge.map((strongKnowledge) => {
          return (
            <Badge
              key={strongKnowledge}
              text={strongKnowledge}
              color={getRandomBackgroundColor()}
              textDark={Math.random() >= 0.5}
            />
          );
        })}
      </React.Fragment>
    );
  };

  getMediumKnowledgeContent = () => {
    const mediumKnowledge = [
      "Cryptology",
      "Signal Processing",
      "Bash",
      "React.js",
      "JavaScript",
      "Assembly (X86 Architecture)",
    ];

    return (
      <React.Fragment>
        {mediumKnowledge.map((strongKnowledge) => {
          return (
            <Badge
              key={strongKnowledge}
              text={strongKnowledge}
              color={getRandomBackgroundColor()}
              textDark={Math.random() >= 0.5}
            />
          );
        })}
      </React.Fragment>
    );
  };

  getMaster = () => {
    return (
      <div>
        <h3>Vrije Universiteit Amsterdam</h3>
        <p>Faculty of Science</p>
        <p>Graduation year: 2024</p>
        <p>M.S. Artificial Intelligence</p>
        <p>
          Website:{" "}
          <span>
            <a target="_blank" href="https://vu.nl/en" rel="noreferrer">
              <i className="fas fa-globe aboutIcon"></i>
            </a>
          </span>
        </p>
      </div>
    );
  };

  getUniversity = () => {
    return (
      <div>
        <h3>University Politehnica of Bucharest</h3>
        <p>Faculty of Automatic Control and Computer Science</p>
        <p>Graduation year: 2022</p>
        <p>Computer Science and Engineering Department</p>
        <p>
          Website:{" "}
          <span>
            <a target="_blank" href="https://acs.pub.ro/" rel="noreferrer">
              <i className="fas fa-globe aboutIcon"></i>
            </a>
          </span>
        </p>
      </div>
    );
  };

  getHighSchool = () => {
    return (
      <div>
        <h3>George Cosbuc National College of Motru</h3>
        <p>Mathematics - Computer Science department</p>
        <p>
          Website:{" "}
          <span>
            <a
              target="_blank"
              href="https://www.facebook.com/ColegiulNationalGeorgeCosbucMotru/"
              rel="noreferrer"
            >
              <i className="fas fa-globe aboutIcon"></i>
            </a>
          </span>
        </p>
      </div>
    );
  };
  getSoftwareEngineerExperience = () => {
    return (
      <div>
        <p>Company: ClearBlue Markets</p>
        <p>
          Website:{" "}
          <span>
            <a target="_blank" href="https://www.clearbluemarkets.com/" rel="noreferrer">
              <i className="fas fa-globe aboutIcon"></i>
            </a>
          </span>
        </p>
        <p>Domain: Software development, Environmental services</p>
        <p>Location: remote</p>
        <p>Projects: ClearBlue Markets platform</p>
      </div>
    );
  };
  getBackEndDeveloperExperience = () => {
    return (
      <div>
        <p>Company: V.I.E.W. Company</p>
        <p>
          Website:{" "}
          <span>
            <a target="_blank" href="https://www.view-bi.com/" rel="noreferrer">
              <i className="fas fa-globe aboutIcon"></i>
            </a>
          </span>
        </p>
        <p>Domain: Software development</p>
        <p>Location: remote</p>
        <p>Projects: Optimus, Prim, WorkflowEngine</p>
      </div>
    );
  };
  getSoftwareConsultantExperience = () => {
    return (
      <div>
        <p>Company: SIGARCH TECHNOLOGIES S.R.L.</p>
        <p>
          {" "}
          Website:
          <span>
            <a target="_blank" href="https://www.forktex.com/" rel="noreferrer">
              <i className="fas fa-globe aboutIcon"></i>
            </a>
          </span>
        </p>
        <p>Domain: Software development</p>
        <p>Location: remote</p>
        <p>Projects: Backend SaaS, Conta</p>
      </div>
    );
  };
  getUnqualifiedWorkerExperience = () => {
    return (
      <div>
        <p>Domain: Constructions (Interior design)</p>
        <p>Location: Germany: Schmähingen (Bavaria)</p>
        <p>Projects: House Renovation</p>
      </div>
    );
  };
  getWorkExperience = (experienceType) => {
    switch (experienceType) {
      case "Software Engineer":
        return this.getSoftwareEngineerExperience();
      case "Backend Developer":
        return this.getBackEndDeveloperExperience();
      case "Software Consultant":
        return this.getSoftwareConsultantExperience();
      case "Unqualified Worker":
        return this.getUnqualifiedWorkerExperience();
      default:
        throw Error("Work Experience error");
    }
  };

  render() {
    const strongLanguages = this.getLanguages("strong");
    const mediumLanguages = this.getLanguages("medium");
    const wannabeLanguages = this.getLanguages("wannabe");

    return (
      <React.Fragment>
        <div
          className="container bg-secondary text-center"
          style={{ marginTop: "2%" }}
        >
          {/* SKILLS */}
          <div className="row">
            <h2 style={{ padding: "2%" }}>Skills</h2>
            <div className="col-lg-12 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Strong Knowledge"
                content={this.getStrongKnowledgeContent()}
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Medium Knowledge"
                content={this.getMediumKnowledgeContent()}
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Sufficient Knowledge"
                content={this.getSufficientKnowledgeContent()}
              />
            </div>
          </div>
          {/* Work Experience */}
          <div className="row">
            <h2 style={{ padding: "2%" }}>Work Experience</h2>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Software Consultant"
                content={this.getSoftwareConsultantExperience()}
                footerInformation="July 2021 - Present"
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Software Engineer"
                content={this.getSoftwareEngineerExperience()}
                footerInformation="December 2022 - October 2023"
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Backend Developer"
                content={this.getBackEndDeveloperExperience()}
                footerInformation="July 2020 - November 2021"
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Unqualified Worker"
                content={this.getUnqualifiedWorkerExperience()}
                footerInformation="July 2018 - August 2018"
              />
            </div>
          </div>
          {/* Eduation */}
          <div className="row">
            <h2 style={{ padding: "2%" }}>Education</h2>
            <div className="col-lg-12 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Master"
                content={this.getMaster()}
                footerInformation="September 2022 - July 2024"
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="Bachelors"
                content={this.getUniversity()}
                footerInformation="October 2018 - July 2022"
              />
            </div>
            <div className="col-lg-6 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="High School"
                content={this.getHighSchool()}
                footerInformation={"2014 – 2018"}
              />
            </div>
          </div>

          {/* Languages */}
          <div className="row">
            <h2 style={{ padding: "2%" }}>Languages</h2>
            <div className="col-lg-4 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="STRONG KNOWLEDGE"
                content={strongLanguages}
              />
            </div>
            <div className="col-lg-4 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="MEDIUM KNOWLEDGE"
                content={mediumLanguages}
              />
            </div>
            <div className="col-lg-4 bg-light" style={{ padding: "2%" }}>
              <InformationItem
                title="WANNABE KNOWLEDGE"
                content={wannabeLanguages}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
