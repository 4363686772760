import React from "react";

const InformationItem = ({ title, content, footerInformation }) => {
  return (
    <div
      className="card text-center"
      style={{ padding: "1%", marginTop: "1%" }}
    >
      <div className="card-header">{title}</div>
      <div className="card-body">
        <div className="card-text">{content}</div>
      </div>
      {footerInformation && (
        <div className="card-footer text-muted">{footerInformation}</div>
      )}
    </div>
  );
};

export default InformationItem;
