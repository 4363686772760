import React from "react";
import ContactForm from "../Components/contactForm";

class Contact extends React.Component {
  render() {
    return (
      <div className="container" style={{ marginTop: "2%" }}>
        <ContactForm />
      </div>
    );
  }
}

export default Contact;
