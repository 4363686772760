function getRandomBackgroundColor() {
  const badges = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
  ];
  return badges[Math.floor(Math.random() * badges.length)];
}

export { getRandomBackgroundColor };
