import React from "react";
import InformationItem from "../Components/Common/informationItem";
import projectsJson from "./resources/projects.json";

class Projects extends React.Component {
  prepareFinalProjectTitle = (title, parenthesesContent) => {
    return (
      <React.Fragment>
        <b>{title}</b> <i>{parenthesesContent}</i>
      </React.Fragment>
    );
  };

  prepareFinalFooterInformation = (startDate, endDate) => {
    return (
      <React.Fragment>
        <span style={{ color: "green" }}>{startDate}</span>
        <span style={{ color: "black" }}> --- </span>
        <span style={{ color: "red" }}>{endDate}</span>
      </React.Fragment>
    );
  };

  prepareContentCodebase = (publicLink) => {
    if (publicLink !== null) {
      return <span>
        <a
          target="_blank"
          href={publicLink}
          rel="noreferrer"
        >
          <i className="fas fa-globe aboutIcon"></i>
        </a>
      </span>
    } else {
      return <span>
        <b>Private</b>
      </span>
    }
  }

  prepareContent = ({ paragraphs, publicLink }) => {
    return <React.Fragment>
      {paragraphs.map(paragraph => <p key={paragraph}>{paragraph}</p>)}
      <p>Code base: {this.prepareContentCodebase(publicLink)}</p>
    </React.Fragment>;
  }

  render() {
    const projects = projectsJson.map((project) =>
      <div key={project.title} className="col-lg-4 bg-light" style={{ padding: "2%" }}>
        <InformationItem
          title={
            this.prepareFinalProjectTitle(
              project.title,
              `(${project.technologies.join(", ")})`
            )
          }
          content={this.prepareContent(project)}
          footerInformation={
            this.prepareFinalFooterInformation(
              project.startDate,
              project.endDate
            )
          }
        />
      </div>)
    return (
      <div className="container" style={{ marginTop: "2%" }}>
        <div className="row">
          {projects}
        </div>
      </div>
    );
  }
}

export default Projects;
