import { React } from "react";
import { NavLink } from "react-router-dom";
import "./navBar.css";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-mainbg">
      <span className="navbar-brand navbar-logo" to="/">
        Alexandru Dochian
      </span>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fas fa-bars text-white"></i>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <NavLink
          className="nav-link Nav_link"
          activeClassName="navBarActive"
          to="/"
          exact
        >
          <i className="fas fa-home"></i>Home
        </NavLink>

        <NavLink
          className="nav-link Nav_link"
          activeClassName="navBarActive"
          to="/about"
          exact
        >
          <i className="fas fa-user-secret"></i>About
        </NavLink>

        <NavLink
          className="nav-link Nav_link"
          activeClassName="navBarActive"
          to="/projects"
          exact
        >
          <i className="fas fa-hammer"></i>Projects
        </NavLink>
        <NavLink
          className="nav-link Nav_link"
          activeClassName="navBarActive"
          to="/contact"
          exact
        >
          <i className="fas fa-check"></i>Contact
        </NavLink>
      </div>
    </nav>
  );
};
export default NavBar;
