import React from "react";
import InformationItem from "../Components/Common/informationItem";
import "../App.css";
import "./home.css";
import mainIcon from "../Images/adochian.jpg";
import Image from "../Components/Image";

class Home extends React.Component {
  getPersonalInformation = () => {
    return (
      <div>
        <p>Location: Bucharest, Romania</p>
        <p>Telephone NL: +31 626 532 294</p>
        <p>Telephone RO: +40 784 935 666</p>
        <p>Email: alexandru.dochian@forktex.com</p>
        <span>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/alexandru-dochian-439197196/"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin homeIcon"></i>
          </a>
        </span>
        <span>
          <a
            target="_blank"
            href="https://github.com/alexandru-dochian"
            rel="noreferrer"
          >
            <i className="fab fa-github homeIcon"></i>
          </a>
        </span>
      </div>
    );
  };

  getCompanyInformation = () => {
    return (
      <div>
        <p>Location: Bucharest, Romania</p>
        <p>Telephone: +40 784 935 666</p>
        <p>Email: alexandru.dochian@forktex.com</p>

        <span>
          <a target="_blank" href="https://www.forktex.com/" rel="noreferrer">
            <i className="fas fa-globe homeIcon"></i>
          </a>
        </span>
        <span>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/forktex"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin homeIcon"></i>
          </a>
        </span>
      </div>
    );
  };

  render() {
    return (
      <div className="container" style={{ marginTop: "1%" }}>
        <h4 style={{ textAlign: "center" }}>
          <cite>
            Passionate about technology and science.
            <br />I endlessly pursue to build state-of-the-art software systems.{" "}
          </cite>
        </h4>
        <div className="row">
          <div
            className="col-lg-12"
            style={{ textAlign: "center", height: "300px" }}
          >
            <Image src={mainIcon} />
          </div>

          <div className="col-lg-6 informationItem">
            <InformationItem
              title="Personal information"
              content={this.getPersonalInformation()}
            />
          </div>

          <div className="col-lg-6 informationItem">
            <InformationItem
              title="FORKTEX"
              content={this.getCompanyInformation()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
