import React from "react";

const Image = ({ src }) => {
  return (
    <div>
      <img
        src={src}
        alt="img"
        style={{
          height: "300px",
          marginTop: "100",
        }}
      />
    </div>
  );
};

export default Image;
