import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import About from "./About/about";
import Home from "./Home/home";
import Projects from "./Projects/projects";
import NavBar from "./Components/NavBar/navBar";
import Contact from "./Contact/contact";
import { ToastContainer } from "react-toastify";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <NavBar />
        <ToastContainer containerId={"CONTACT_SUCCESS"} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/projects" exact>
            <Projects />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
