import React from "react";
import Joi from "react-native-joi";
import Form from "./Common/form";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { be_url } from "../config.json";
import "react-toastify/dist/ReactToastify.css";

class ContactForm extends Form {
  state = {
    data: {
      email: "",
      message: "",
    },
    errors: {},
  };

  schema = {
    email: Joi.string().required().email().label("Email"),
    message: Joi.string().required().label("Message"),
  };

  doSubmit = async () => {
    const data = this.state.data;
    data['source'] = 'personal_website';
    const url = `${be_url}/contact`;

    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Thank you for your message!", {
            containerId: "CONTACT_SUCCESS",
          });
          this.setState({
            data: { email: "", message: "" },
          });
          this.props.history.push("/");
        } else {
          toast.error(
            "Sorry, the server failed to process your request. Are you trying something sneaky?",
            { containerId: "FORM_FAIL" }
          );
        }
      })
      .catch((err) => {
        toast.error("Sorry, the server is down, try buzz me later!", {
          containerId: "FORM_FAIL",
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="container bg-light text-center"
          style={{ padding: "5%", fontSize: "20px" }}
        >
          <ToastContainer containerId={"FORM_FAIL"} />
          <div className="col-lg-12">
            <h2>Send me a message</h2>
            <br />
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("email", "Email")}
              <br />
              {this.renderTextArea("message", "Message")}
              <br />
              {this.renderButton("Send")}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ContactForm);
