import React from "react";

const Badge = ({ text, color = "primary", textDark = true, margin = "2%" }) => {
  const className = "badge bg-" + color + (textDark ? " text-dark" : "");
  const style = { margin, padding: "2%", fontSize: "10px" };
  return (
    <span className={className} style={style}>
      {text}
    </span>
  );
};

export default Badge;
